import { Axios } from "../helpers/axios-config";

export default {
  namespaced: true,
  state: {
    summary_data: [],
    month_report_data: [],
    bank_report_data: [],
    channel_report_data: [],
    mda_report_data: [],
    revenue_item_report_data: [],
    single_mda_report_data: [],
    single_bank_report_data: [],
    single_item_report_data: [],
    single_payer_report_data: [],
    other_revenues: [],
  },
  getters: {
    summaryData: (state) => state.summary_data,
    monthReportData: (state) => state.month_report_data,
    bankReportData: (state) => state.bank_report_data,
    channelReportData: (state) => state.channel_report_data,
    mdaReportData: (state) => state.mda_report_data,
    revenueItemReportData: (state) => state.revenue_item_report_data,
    singleMdaReportData: (state) => state.single_mda_report_data,
    singleBankReportData: (state) => state.single_bank_report_data,
    singleItemReportData: (state) => state.single_item_report_data,
    singlePayerReportData: (state) => state.single_payer_report_data,
    otherRevenues: (state) => state.other_revenues,
  },
  mutations: {
    SUMMARY_DATA: (state, payload) => {
      state.summary_data = payload;

      if (payload.length > 0) {
        let channel = "All";
        let currYear = {
          Amount: 0,
          Txn: 0,
        };
        let prevYear = { Amount: 0, Txn: 0 };

        payload.forEach((data) => {
          currYear.Amount += parseFloat(data.data.currYear.Amount);
          prevYear.Amount += parseFloat(data.data.prevYear.Amount);
          currYear.Txn += parseFloat(data.data.currYear.Txn);
          prevYear.Txn += parseFloat(data.data.prevYear.Txn);
        });
        state.summary_data.unshift({
          channel,
          data: { currYear, prevYear },
        });
      }
    },
    UPDATE_SUMMARY_DATA: (state, payload) => state.summary_data.push(payload),
    MONTH_REPORT_DATA: (state, payload) => (state.month_report_data = payload),
    BANK_REPORT_DATA: (state, payload) => (state.bank_report_data = payload),
    CHANNEL_REPORT_DATA: (state, payload) =>
      (state.channel_report_data = payload),
    MDA_REPORT_DATA: (state, payload) => (state.mda_report_data = payload),
    REVENUE_ITEM_REPORT_DATA: (state, payload) =>
      (state.revenue_item_report_data = payload),
    SINGLE_MDA_REPORT_DATA: (state, payload) =>
      (state.single_mda_report_data = payload),
    SINGLE_BANK_REPORT_DATA: (state, payload) =>
      (state.single_bank_report_data = payload),
    SINGLE_ITEM_REPORT_DATA: (state, payload) =>
      (state.single_item_report_data = payload),
    SINGLE_PAYER_REPORT_DATA: (state, payload) =>
      (state.single_payer_report_data = payload),
    OTHER_REVENUES: (state, payload) => (state.other_revenues = payload),
  },
  actions: {
    updateOtherRevenues({ commit }, payload) {
      commit("OTHER_REVENUES", payload);
    },
    updateSummaryData({ commit }, payload) {
      commit("UPDATE_SUMMARY_DATA", payload);
    },
    getSummaryData({ commit }, payload) {
      // payload.reportType = 'payment';
      return new Promise((res, rej) => {
        Axios.post("report/paymentsByChannel", payload)
          .then((result) => {
            const { status, message, data } = result.data;
            if (status.toLowerCase() === "success") {
              commit("SUMMARY_DATA", data);
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    getMonthsChartData({ commit }, payload) {
      // payload.reportType = 'payment';
      return new Promise((res, rej) => {
        Axios.post("report/paymentsByMonth", payload)
          .then((result) => {
            const { status, message, data } = result.data;
            if (status.toLowerCase() === "success") {
              commit("MONTH_REPORT_DATA", data);
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    getBanksChartData({ commit }, payload) {
      // payload.reportType = 'payment';
      return new Promise((res, rej) => {
        Axios.post("report/paymentsByBank", payload)
          .then((result) => {
            const { status, message, data } = result.data;
            if (status.toLowerCase() === "success") {
              commit("BANK_REPORT_DATA", data);
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    getChannelsChartData({ commit }, payload) {
      return new Promise((res, rej) => {
        // payload.reportType = 'payment';
        Axios.post("report/paymentsByChannel", payload)
          .then((result) => {
            const { status, message, data } = result.data;
            if (status.toLowerCase() === "success") {
              commit("CHANNEL_REPORT_DATA", data);
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    getMDAsChartData({ commit }, payload) {
      return new Promise((res, rej) => {
        // payload.reportType = 'payment';
        Axios.post("report/paymentsByRevenueCategory", payload)
          .then((result) => {
            const { status, message, data } = result.data;
            if (status.toLowerCase() === "success") {
              commit("MDA_REPORT_DATA", data);
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    getRevenueItemsChartData({ commit }, payload) {
      return new Promise((res, rej) => {
        // payload.reportType = 'payment';
        Axios.post("report/paymentsByRevenueItem", payload)
          .then((result) => {
            const { status, message, data } = result.data;
            if (status.toLowerCase() === "success") {
              commit("REVENUE_ITEM_REPORT_DATA", data);
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    getMonthlyPaymentsByItem({ commit }, payload) {
      return new Promise((res, rej) => {
        // payload.reportType = 'payment';
        Axios.post("report/monthlyPaymentsByItem", payload)
          .then((result) => {
            const { status, message, data } = result.data;
            if (status.toLowerCase() === "success") {
              if (payload.filterColumn === "revenue_category")
                commit("SINGLE_MDA_REPORT_DATA", data);
              if (payload.filterColumn === "bank")
                commit("SINGLE_BANK_REPORT_DATA", data);
              if (payload.filterColumn === "revenue_item")
                commit("SINGLE_ITEM_REPORT_DATA", data);
              if (payload.filterColumn === "customer_name")
                commit("SINGLE_PAYER_REPORT_DATA", data);

              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    generateSpecialRevenueReport(__, payload) {
      return new Promise((res, rej) => {
        Axios.defaults.responseType = "blob";
        Axios.post("report/generatePaymentReportPDF", payload, {
          timeout: 500000,
        })
          .then((result) => {
            if (result.data.type === "application/json") {
              // rej(result.data.msg);

              const blb = new Blob([result.data], { type: "text/plain" });
              const reader = new FileReader();
              let jsonRes;
              reader.addEventListener("loadend", (e) => {
                const text = e.srcElement.result;
                jsonRes = JSON.parse(text);
                if (jsonRes.status === "error")
                  rej(jsonRes.msg || jsonRes.message);
                else res(jsonRes);
              });

              reader.readAsText(blb);
            } else {
              res({
                msg: "Report generated successfully",
                data: result.data,
              });
            }
          })
          .catch((err) => {
            rej(err.message);
          })
          .then(() => {
            Axios.defaults.responseType = "json";
          });
      });
    },
  },
};
