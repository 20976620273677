import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@/assets/styles/main.css";
import VueClickAway from "vue3-click-away";
import VeeValidate from "@/helpers/validator.js";
import VueApexCharts from "vue3-apexcharts";
import VueSimpleContextMenu from "vue-simple-context-menu";

import mitt from "mitt";
import "./assets/js/printThis";

export const emitter = mitt();

import { createApp } from "vue";
const app = createApp(App);
app.component(VueSimpleContextMenu);
app.config.globalProperties.$filters = {
  currencyFormat(value) {
    if (typeof value !== "number") {
      return value;
    } else {
      var formatter = new Intl.NumberFormat("NGN", {
        style: "currency",
        currency: "NGN",
        currencySign: "accounting",
        currencyDisplay: "symbol",
      });
      return formatter.format(value);
    }
  },
};

app
  .use(router)
  .use(store)
  .use(VueClickAway)
  .use(VeeValidate)
  .use(VueApexCharts);
app.mount("#app");
