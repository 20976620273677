import { Axios } from "../helpers/axios-config";
// import { unit, role } from "@/helpers/login_details";

// let url = "verify";

// function setupUrl() {
//   if (unit === "chairman" && role === "director") url = "authorize";
//   if (unit === "finance" && role === "director") url = "approve";
//   if (unit === "stampduty" && role === "unithead") url = "verify";
// }

export default {
  namespaced: true,
  state: {
    invoices: [],
  },
  getters: {
    invoices: (state) => state.invoices,
  },
  mutations: {
    INVOICES: (state, payload) => (state.invoices = payload),
  },
  actions: {
    async getInvoice({ commit }, payload) {
      try {
        let result = await Axios.get(`invoice/${payload}`);

        commit("INVOICES", [result.data.data]);
      } catch (error) {
        console.log(error);
      }
    },
    reprintInvoice(__, payload) {
      return new Promise((res, rej) => {
        Axios.defaults.responseType = "blob";
        Axios.get("invoice/reprint/" + payload, { timeout: 50000 })
          .then((result) => {
            if (result.data.type === "application/json") {
              // rej(result.data.msg);

              const blb = new Blob([result.data], {
                type: "text/plain",
              });
              const reader = new FileReader();
              let jsonRes;
              reader.addEventListener("loadend", (e) => {
                const text = e.srcElement.result;
                jsonRes = JSON.parse(text);
                if (jsonRes.status === "error")
                  rej(jsonRes.msg || jsonRes.message);
                else res(jsonRes);
              });

              reader.readAsText(blb);
            } else {
              res({
                msg: "Stamp duty invoice regenerated successfully",
                data: result.data,
              });
            }
          })
          .catch((err) => {
            rej(err.message);
          })
          .then(() => {
            Axios.defaults.responseType = "json";
          });
      });
    },
    async updateInvoice(__, payload) {
      return new Promise((res, rej) => {
        Axios.put(`invoice/${payload.id}`, {
          receipt_numbers: payload.receipts,
        })
          .then((result) => {
            const { status, message, msg } = result.data;

            if (status.toLowerCase() === "success") {
              res(message || msg);
            } else {
              rej(message || msg);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
  },
};
