import { Axios } from "../helpers/axios-config";
// import assessment from '../mixins/assessment';
// import router from "@/router";

export default {
  namespaced: true,
  state: {
    returns: [],
    returns_schedule: [],
  },
  getters: {
    getReturns: (state) => state.returns,
    getReturnsSchedule: (state) => state.returns_schedule,
  },
  mutations: {
    RETURNS: (state, payload) => {
      state.returns = payload;
    },
    RETURNS_SCHEDULE: (state, payload) => {
      state.returns_schedule = payload;
    },
  },
  actions: {
    getAnnualReturnsForAcknowledgment({ commit }) {
      return new Promise((resolve, reject) => {
        Axios.get(`returns`)
          .then((result) => {
            if (result.data.status !== "error")
              commit("RETURNS", result.data.data);
            else {
              reject(result.data.msg);
            }

            resolve("done");
          })
          .catch((err) => {
            console.log(err.message);
            reject(err.message);
          });
      });
    },
    getAnnualReturnsForAcknowledgmentById({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.get(`returns/${payload}`)
          .then((result) => {
            if (result.data.status !== "error")
              commit("RETURNS_SCHEDULE", result.data.data);
            else {
              reject(result.data.msg);
            }

            resolve("done");
          })
          .catch((err) => {
            console.log(err.message);
            reject(err.message);
          });
      });
    },
    acknowledgeReturns({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`returns/acknowledge`, payload)
          .then((result) => {
            if (result.data.status !== "error") {
              commit("RETURNS_SCHEDULE", []);
              resolve("done");
            } else {
              reject(result.data.msg);
            }
          })
          .catch((err) => {
            console.log(err.message);
            reject(err.message);
          });
      });
    },
  },
};
