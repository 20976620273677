import {
  Field as VeeField,
  Form as VeeForm,
  defineRule,
  configure,
} from "vee-validate";
import { required, min, max } from "@vee-validate/rules";
import { Axios } from "@/helpers/axios-config.js";
import store from "@/store/index";

export default {
  install(app) {
    app.component("vee-field", VeeField);
    app.component("vee-form", VeeForm);

    defineRule("required", required);
    defineRule("min", min);
    defineRule("max", max);
    defineRule(
      "tin",
      async (value) => {
        let valid = false;
        if (value && value.length >= 9) {
          await Axios.get(`taxpayer/${value}`)
            .then((res) => {
              const { status } = res.data;
              if (status !== "error") {
                valid = true;
                store.dispatch("assessment/taxpayer", res.data);
              } else valid = false;
            })
            .catch((err) => {
              console.log(err);
              valid = false;
            });
          return valid;
        } else return "TIN must be 10 digits";
      },
      { initial: false }
    );

    configure({
      validateOnBlur: false, // controls if `blur` events should trigger validation with `handleChange` handler
      validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
      validateOnInput: false,
      validateOnModelUpdate: false,
      generateMessage: (ctx) => {
        const messages = {
          required: `Please enter a value for ${ctx.field}`,
          tin: `Please provide a valid ${ctx.field}`,
        };

        const message = messages[ctx.rule.name]
          ? messages[ctx.rule.name]
          : "Please enter a valid value";

        return message;
      },
    });
  },
};
