import axios from "axios";
import store from "@/store/index";
import { cookies } from "@/helpers/cookie";
import { STORAGE_ITEM_NAME } from "@/helpers/keys";
// import { cookies } from "./cookie";
// import { STORAGE_ITEM_NAME } from "./keys";

// const token = cookies.getJSONCookie(STORAGE_ITEM_NAME).token;
const Axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 200000,
});

Axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.common["Authorization"] = `Bearer ${
      cookies.getJSONCookie(STORAGE_ITEM_NAME).token || ""
    }`;
    config.headers.common["whoiam"] = "back_office";
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    // console.log(response);

    if (response.data.status === "error") {
      if (response.data.msg)
        if (
          response.data.msg.toLowerCase().includes("token") ||
          response.data.msg
            .toLowerCase()
            .includes("access denied, no credentials sent")
        ) {
          store.dispatch("logout");
          window.location.href = "/login";
        }

      if (response.data.message)
        if (
          response.data.message.toLowerCase().includes("token") ||
          response.data.message
            .toLowerCase()
            .includes("access denied, no credentials sent")
        ) {
          store.dispatch("logout");
          window.location.href = "/login";
        }
    }
    return response;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);

export { Axios };
