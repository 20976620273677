import { Axios } from "../helpers/axios-config";
import { unit, role } from "@/helpers/login_details";

let url = "verify";

function setupUrl() {
  if (unit === "chairman" && role === "director") url = "authorize";
  if (unit === "finance" && role === "director") url = "approve";
  if (unit === "stampduty" && role === "unithead") url = "verify";
}

export default {
  namespaced: true,
  state: {
    duties: [],
    duty_rates: [],
    requested_duties: [],
  },
  getters: {
    duties: (state) => state.duties,
    duty_rates: (state) => state.duty_rates,
    requested_duties: (state) => state.requested_duties,
  },
  mutations: {
    DUTIES: (state, payload) => (state.duties = payload),
    DUTY_RATES: (state, payload) => (state.duty_rates = payload),
    REQUESTED_DUTIES: (state, payload) => (state.requested_duties = payload),
    REDUCE_DUTIES(state, payload) {
      // console.log(payload);
      if (payload > 0) {
        let duties = state.duties.filter((r) => {
          return r.id != payload;
        });
        state.duties = duties;
      } else state.duties = [];
    },
  },
  actions: {
    updateSingleStampDuty({ commit }, payload) {
      setupUrl();
      return new Promise((res, rej) => {
        Axios.put(`stampduty/${url}`, payload)
          .then((result) => {
            const { status, message } = result.data;
            if (status.toLowerCase() === "success") {
              commit("REDUCE_DUTIES", payload.ids[0]);
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    updateAllStampDuties({ commit }, payload) {
      setupUrl();
      return new Promise((res, rej) => {
        Axios.put(`stampduty/${url}`, payload)
          .then((result) => {
            const { status, message } = result.data;
            if (status.toLowerCase() === "success") {
              commit("REDUCE_DUTIES", 0);
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    async getDuties({ commit }, payload) {
      try {
        let url = "stampduty?";

        if (payload.start_date && payload.end_date) {
          url =
            url +
            `${payload.item.toLowerCase()}=${payload.value.toLowerCase()}&start_date=${
              payload.start_date
            }&end_date=${payload.end_date}&`;
        }

        if (payload.item && payload.value) {
          url =
            url +
            `${payload.item.toLowerCase()}=${payload.value.toLowerCase()}&`;
        }

        if (payload.status) {
          url = url + `status=${payload.status.toLowerCase()}`;
        }

        let result = await Axios.get(url);

        if (payload.from !== "search")
          commit("REQUESTED_DUTIES", result.data.data);
        else commit("DUTIES", result.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    getStampDutyRequestsFromDB({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.get(`stampduty`, payload)
          .then((result) => {
            if (result.data.status !== "error")
              commit("REQUESTED_DUTIES", result.data.data);
            else {
              reject(result.data.msg || result.data.message);
            }

            resolve(result);
          })
          .catch((err) => {
            console.log(err.message);
            reject(err.message);
          });
      });
    },

    getStampDutyRequestsFromDB2(__, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`stampduty`, payload)
          .then((result) => {
            if (result.data.status === "error") {
              reject(result.data.msg || result.data.message);
            }

            resolve(result.data.data);
          })
          .catch((err) => {
            console.log(err.message);
            reject(err.message);
          });
      });
    },
    async getDutyRates({ commit }) {
      try {
        let result = await Axios.get(`stampduty/rates`);
        commit("DUTY_RATES", result.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    completeStampDutyForVerification(__, payload) {
      return new Promise((resolve, reject) => {
        Axios.put(`stampduty/new/${payload}`)
          .then((result) => {
            if (result.data.status !== "error")
              resolve(result.data.msg || result.data.message);
            else {
              reject(result.data.msg || result.data.message);
            }
          })
          .catch((err) => {
            console.log(err.message);
            reject(err.message);
          });
      });
    },
    // eslint-disable-next-line no-empty-pattern
    // async addStampDuty({}, payload) {
    //   try {
    //     let result = await Axios.post(`stampduty/new`, payload);
    //     console.log(result);
    //     return result.data;
    //   } catch (error) {
    //     console.log(error);
    //     return { status: "error" };
    //   }
    // },
    addStampDuty(__, payload) {
      return new Promise((res, rej) => {
        Axios.defaults.responseType = "blob";
        Axios.post("stampduty/new", payload, { timeout: 50000 })
          .then((result) => {
            if (result.data.type === "application/json") {
              // rej(result.data.msg);

              const blb = new Blob([result.data], {
                type: "text/plain",
              });
              const reader = new FileReader();
              let jsonRes;
              reader.addEventListener("loadend", (e) => {
                const text = e.srcElement.result;
                jsonRes = JSON.parse(text);
                if (jsonRes.status === "error")
                  rej(jsonRes.msg || jsonRes.message);
                else res(jsonRes);
              });

              reader.readAsText(blb);
            } else {
              res({
                msg: "Stamp duty invoice generated successfully",
                data: result.data,
              });
            }
          })
          .catch((err) => {
            rej(err.message);
          })
          .then(() => {
            Axios.defaults.responseType = "json";
          });
      });
    },
    getStampDutyCertificate(__, payload) {
      // console.log(payload);
      return new Promise((res, rej) => {
        Axios.defaults.responseType = "blob";
        Axios.post(
          "stampduty/certificate",
          { certificate_number: payload },
          { timeout: 100000 }
        )
          .then((result) => {
            if (result.data.type === "application/json") {
              // rej(result.data.msg);

              const blb = new Blob([result.data], { type: "text/plain" });
              const reader = new FileReader();
              let jsonRes;
              reader.addEventListener("loadend", (e) => {
                const text = e.srcElement.result;
                jsonRes = JSON.parse(text);
                if (jsonRes.status === "error")
                  rej(jsonRes.msg || jsonRes.message);
                else res(jsonRes);
              });

              reader.readAsText(blb);
            } else {
              res({
                msg: " successfully",
                data: result.data,
              });
            }
          })
          .catch((err) => {
            rej(err.message);
          })
          .then(() => {
            Axios.defaults.responseType = "json";
          });
      });
    },
  },
};
