import { Axios } from "../helpers/axios-config";
// import assessment from '../mixins/assessment';
// import router from "@/router";

export default {
    namespaced: true,
    state: {
        taxpayers: {},

    },
    getters: {
        getTaxpayers: state => state.taxpayers,
    },
    mutations: {
        TAXPAYERS: (state, payload) => {
            state.taxpayers = payload;
        },
    },
    actions: {
        fetchTaxPayers({ commit }, payload) {

            let filter = {...payload};

            if(filter.registration_type === '') filter.registration_type = 'all';
            if(filter.sector === '') filter.sector = 'all';

            return new Promise((res, rej) => {
                Axios.post(`taxpayer/search`, filter).then(response => {
                    const { data, status, msg } = response.data;
                    // console.log(response.data.data);
                    if (status === 'success')
                        commit('TAXPAYERS', data);

                    res(msg);
                }).catch(err => {
                    console.log(err);
                    rej(err.message);
                })
            });
        }
    }
};