import { openDB } from 'idb';

// const dbPromise = () => {
//     if (!('indexDB' in window)) {
//         console.log("Browser does not support indexDB")
//         throw new Error('Browser does not support indexDB');
//     }
//     console.log("Inside Index DB")
//     return openDB('Payers', 1, {
//         upgrade(db) {
//             db.createObjectStore('payers');
//         },
//     })

// }
const dbPromise = openDB('Payers', 1, {
    upgrade(db) {
        db.createObjectStore('payers');
    },
})
export async function getPayer(key) {
    return (await dbPromise).get('payers', key)
}
export async function getAllPayers() {
    return (await dbPromise).getAll('payers')
}
export async function insertPayer(key, val) {
    return (await dbPromise).put('payers', val, key)
}