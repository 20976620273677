import { Axios } from "../helpers/axios-config";
// import assessment from '../mixins/assessment';
// import router from "@/router";

export default {
  namespaced: true,
  state: {
    taxpayer: {},
    presumptiveAssessmentDemandNotice: "",
    incomes: [],
    deductions: [],
    bim_levies: [],
    assessments: [],
    assessment_requests: [],
    assessment_properties: [],
    bayelsa_lgas: {
      Igbogene: "IGB",
      Yenegwue: "YGW",
      Akenfa: "AKF",
      Agudama: "AGM",
      Akenpai: "AKP",
      Edepie: "EDP",
      Etegwue: "ETG",
      Okutukutu: "OKU",
      Opolo: "OPO",
      Biogbolo: "BGL",
      "Yeneizue gene": "YZG",
      "Yeneizue-epie": "YZE",
      Kpansia: "KPS",
      Okaka: "OKA",
      Ekeki: "EKE",
      Azikoro: "AZK",
      Amarata: "AMT",
      Onopa: "ONP",
      Ovom: "OVM",
      Yenagoa: "YEN",
      Swali: "SWL",
      Akaba: "AKB",
      Fangbe: "FGB",
    },
  },
  getters: {
    getTaxpayer: (state) => state.taxpayer,
    getPresumptiveAssessmentDemandNotice: (state) =>
      state.presumptiveAssessmentDemandNotice,
    getBimLevies: (state) => state.bim_levies,
    getPayeIncomes: (state) => state.incomes,
    getPayeDeductions: (state) => state.deductions,
    getAssessments: (state) => state.assessments,
    getAssessmentRequests: (state) => state.assessment_requests,
    getBayelsaLgas: (state) => state.bayelsa_lgas,
    getAssessmentProperties: (state) => state.assessment_properties,
  },
  mutations: {
    taxpayer: (state, payload) => {
      state.taxpayer = payload;
    },
    preAssessDemandNotice: (state, payload) => {
      state.presumptiveAssessmentDemandNotice = payload;
    },
    BIM_LEVIES: (state, payload) => {
      state.bim_levies = payload;
    },
    PAYE_INCOMES: (state, payload) => {
      state.incomes.push(payload);
    },
    PAYE_DEDUCTIONS: (state, payload) => {
      state.deductions.push(payload);
    },
    ASSESSMENTS: (state, payload) => {
      state.assessments = payload;
    },
    ASSESSMENT_REQUESTS: (state, payload) => {
      state.assessment_requests = payload;
    },
    ASSESSMENT_PROPERTIES: (state, payload) => {
      state.assessment_properties = payload;
    },
    REDUCE_ASSESSMENTS(state, payload) {
      // console.log(payload);
      if (payload > 0) {
        let assessments = state.assessment_requests.filter((r) => {
          return r.id != payload;
        });
        state.assessment_requests = assessments;
      } else state.assessment_requests = [];
    },
  },
  actions: {
    authorizeSingleAssessment({ commit }, payload) {
      return new Promise((res, rej) => {
        Axios.post("approveAssessment", payload)
          .then((result) => {
            const { status, message } = result.data;
            if (status.toLowerCase() === "success") {
              commit("REDUCE_ASSESSMENTS", payload.ids[0]);
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    authorizeAllAssessments({ commit }, payload) {
      return new Promise((res, rej) => {
        Axios.post("approveAssessment", payload)
          .then((result) => {
            const { status, message } = result.data;
            if (status.toLowerCase() === "success") {
              commit("REDUCE_ASSESSMENTS", 0);
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    fetchAssessmentsFromDB({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`taxpayer/assessments`, { status: "all", ...payload })
          .then((result) => {
            if (result.data.status !== "error")
              commit("ASSESSMENTS", result.data.data);
            else {
              reject(result.data.msg || result.data.message);
            }

            resolve(result);
          })
          .catch((err) => {
            console.log(err.message);
            reject(err.message);
          });
      });
    },
    fetchAssessmentRequestsFromDB({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`taxpayer/assessments`, payload)
          .then((result) => {
            if (result.data.status !== "error")
              commit("ASSESSMENT_REQUESTS", result.data.data);
            else {
              reject(result.data.msg || result.data.message);
            }

            resolve(result);
          })
          .catch((err) => {
            console.log(err.message);
            reject(err.message);
          });
      });
    },
    taxpayer({ commit }, payload) {
      commit("taxpayer", payload);
    },
    addPayeIncome: ({ commit }, payload) => {
      commit("PAYE_INCOMES", payload);
    },
    addPayeDeduction: ({ commit }, payload) => {
      commit("PAYE_DEDUCTIONS", payload);
    },

    removePayeIncome: ({ state }, payload) => {
      state.incomes.splice(payload, 1);
    },
    removePayeDeduction: ({ state }, payload) => {
      state.deductions.splice(payload, 1);
    },
    resetPayeAssessmentValues: ({ state }) => {
      state.incomes = [];
      state.deductions = [];
    },
    getBimLeviesFromDB({ commit }) {
      return new Promise((resolve, reject) => {
        Axios.get(`taxpayer/bimLevies`)
          .then((result) => {
            if (result.data.status !== "error")
              commit("BIM_LEVIES", result.data.data);
            else {
              reject(result.data.msg);
            }

            resolve("done");
          })
          .catch((err) => {
            console.log(err.message);
            reject(err.message);
          });
      });
    },
    getProperties({ commit }, propertyType) {
      return new Promise((resolve, reject) => {
        Axios.post(`assessment/listProperty`, propertyType)
          .then((result) => {
            if (result.data.status !== "error")
              commit("ASSESSMENT_PROPERTIES", result.data.data);
            else {
              reject(result.data.msg);
            }

            resolve("done");
          })
          .catch((err) => {
            console.log(err.message);
            reject(err.message);
          });
      });
    },
    getPropertyTaxResult(__, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(payload.url, { ...payload.data })
          .then((result) => {
            if (result.data.status !== "error") resolve(result.data.data);
            else {
              reject(result.data.msg);
            }
          })
          .catch((err) => {
            console.log(err.message);
            reject(err.message);
          });
      });
    },
    assessTaxPayer2(__, payload) {
      let url;

      switch (payload.type) {
        case "Presumptive": {
          url = "taxpayer/presumptiveAssessment";
          break;
        }
        case "BIM": {
          url = "taxpayer/bimAssessment";
          break;
        }
        case "PAYE": {
          url = "taxpayer/payeAssessment";
          break;
        }
      }

      return new Promise((res, rej) => {
        if (payload.mode === "get") {
          // Axios.defaults.responseType = "blob";
          Axios.post(url, payload.data)
            .then((result) => {
              if (result.data.status === "error")
                rej(result.data.message || result.data.msg);
              res(result);
            })
            .catch((err) => {
              rej(err.message);
            });
        } else {
          Axios.defaults.responseType = "blob";
          Axios.post(url, payload.data)
            .then((result) => {
              if (result.data.type === "application/json") {
                // rej(result.data.msg);

                const blb = new Blob([result.data], { type: "text/plain" });
                const reader = new FileReader();
                let jsonRes;
                reader.addEventListener("loadend", (e) => {
                  const text = e.srcElement.result;
                  jsonRes = JSON.parse(text);
                  if (jsonRes.status === "error")
                    rej(jsonRes.msg || jsonRes.message);
                  else res(jsonRes);
                });

                reader.readAsText(blb);
              } else {
                res({
                  msg: "Assessment saved successfully",
                  data: result.data,
                });
              }
            })
            .catch((err) => {
              rej(err.message);
            })
            .then(() => {
              Axios.defaults.responseType = "json";
            });
        }
      });
    },
    assessTaxPayer(__, payload) {
      let url;

      switch (payload.type) {
        case "Presumptive": {
          url = "taxpayer/presumptiveAssessment";
          break;
        }
        case "BIM": {
          url = "taxpayer/bimAssessment";
          break;
        }
        case "PAYE": {
          url = "taxpayer/payeAssessment";
          break;
        }
      }

      return new Promise((res, rej) => {
        if (payload.mode === "get") {
          // Axios.defaults.responseType = "blob";
          Axios.post(url, payload.data)
            .then((result) => {
              if (result.data.status === "error")
                rej(result.data.message || result.data.msg);
              res(result);
            })
            .catch((err) => {
              rej(err.message);
            });
        } else {
          // Axios.defaults.responseType = "blob";
          Axios.post(url, payload.data, { timeout: 50000 })
            .then((result) => {
              const { msg, message, status } = result.data;

              if (status === "error") {
                rej(msg || message);
              } else {
                res(msg || message);
              }
            })
            .catch((err) => {
              rej(err.message);
            });
        }
      });
    },
    getAssessmentBill(__, payload) {
      // console.log(payload);
      return new Promise((res, rej) => {
        Axios.defaults.responseType = "blob";
        Axios.post(
          "taxpayer/generateDemandNotice",
          { assessment_ref: payload },
          { timeout: 50000 }
        )
          .then((result) => {
            if (result.data.type === "application/json") {
              // rej(result.data.msg);

              const blb = new Blob([result.data], { type: "text/plain" });
              const reader = new FileReader();
              let jsonRes;
              reader.addEventListener("loadend", (e) => {
                const text = e.srcElement.result;
                jsonRes = JSON.parse(text);
                if (jsonRes.status === "error")
                  rej(jsonRes.msg || jsonRes.message);
                else res(jsonRes);
              });

              reader.readAsText(blb);
            } else {
              res({
                msg: "Assessment saved successfully",
                data: result.data,
              });
            }
          })
          .catch((err) => {
            rej(err.message);
          })
          .then(() => {
            Axios.defaults.responseType = "json";
          });
      });
    },
  },
};
