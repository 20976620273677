<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
// import Swal from "sweetalert2";;
export default {
  created() { },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Swal.fire2-popup {
  width: auto !important;
}

button {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}
</style>
