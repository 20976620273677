<template>
  <div class="home w-screen h-full">
    <navigation-drawer
      :links="links"
      :notifications="notifications"
      :username="user"
      :designation="designation"
    >
      <!-- <transition name="slide-fade"> -->
      <router-view v-if="showView"></router-view>
      <!-- </transition> -->
    </navigation-drawer>
  </div>
</template>

<script>
import NavigationDrawer from "../components/NavigationDrawer.vue";
import { mapGetters, mapActions } from "vuex";

import {
  user,
  views,
  designation,
  loadDetails,
  role,
  unit,
  mda,
} from "@/helpers/login_details.js";
export default {
  name: "Home",
  components: { NavigationDrawer },
  data: () => ({
    user: user,
    showView: false,
    reload: 0,
    designation: designation,
    unit: "",
    role: "",
    links: [],
    notifications: [],
    allLinks: [
      {
        name: "Tax Clearance",
        path: "/requests",
        icon: "fas fa-mail-bulk",
        dropdown: [],
        drop: false,
        notification: {
          shown: false,
          active: false,
          counter: 0,
        },
      },
      {
        name: "Live Payments",
        path: "/payments",
        icon: "fas fa-money-bill-wave",
        dropdown: [],
        drop: false,
        notification: {
          shown: false,
          active: false,
          counter: 0,
        },
      },
      {
        name: "Tax Audits",
        path: "/audits",
        icon: "fas fa-file-signature",
        dropdown: [],
        drop: false,
        notification: {
          shown: false,
          active: false,
          counter: 0,
        },
      },
      {
        name: "Annual Returns",
        path: "/annual-returns",
        icon: "fas fa-table",
        dropdown: [],
        drop: false,
        notification: {
          shown: false,
          active: false,
          counter: 0,
        },
      },
      {
        name: "Payment History",
        path: "/tpdb",
        icon: "fas fa-book",
        dropdown: [],
        drop: false,
        notification: {
          shown: false,
          active: false,
          counter: 0,
        },
      },

      {
        name: "Assessment",
        path: "/assessment",
        icon: "fas fa-book-reader",
        dropdown: [],
        drop: false,
        notification: {
          shown: false,
          active: false,
          counter: 0,
        },
      },
      {
        name: "Manage Receipts",
        path: "/manage_receipts",
        icon: "fas fa-receipt",
        dropdown: [
          {
            name: "Details",
            path: "/manage_receipts/details",
            icon: "fas fa-info-circle",
            notification: {
              shown: false,
              active: false,
              counter: 0,
            },
          },
          {
            name: "Uploads",
            path: "/manage_receipts/uploads",
            icon: "fas fa-file-upload",
            notification: {
              shown: false,
              active: false,
              counter: 0,
            },
          },
        ],
        drop: false,
      },
      {
        name: "Reports",
        path: "/reports",
        icon: "fas fa-chart-bar",
        drop: false,
        dropdown: [
          {
            name: "Summary",
            path: "/reports/summary",
            icon: "fas fa-chart-line",
            notification: {
              shown: false,
              active: false,
              counter: 0,
            },
          },
          {
            name: "Reconciliation",
            path: "/reports/reconciliation",
            icon: "fas fa-sync-alt",
            notification: {
              shown: false,
              active: false,
              counter: 0,
            },
          },
        ],
      },
      {
        name: "Taxpayers Details",
        path: "/payer_details",
        icon: "fas fa-user",
        dropdown: [],
        drop: false,
        notification: {
          shown: false,
          active: false,
          counter: 0,
        },
      },
      {
        name: "StampDuty",
        path: "/stamp_duty",
        icon: "fas fa-stamp",
        dropdown: [],
        drop: false,
        notification: {
          shown: false,
          active: false,
          counter: 0,
        },
      },
      {
        name: "Property Details",
        path: "/property_details",
        icon: "fas fa-home",
        dropdown: [],
        drop: false,
        notification: {
          shown: false,
          active: false,
          counter: 0,
        },
      },
      {
        name: "Settings",
        path: "/settings",
        icon: "fas fa-cog",
        dropdown: [],
        drop: false,
        notification: {
          shown: false,
          active: false,
          counter: 0,
        },
      },
    ],
  }),
  computed: {
    ...mapGetters(["getRequestCount", "getAuditRequests"]),
  },
  methods: {
    ...mapActions(["getAllAuditRequests"]),

    checkForNotifications() {
      this.notifications = [];
      this.allLinks.forEach((link) => {
        if (link.notification) {
          if (link.notification.counter > 0) {
            this.notifications.push({
              name: link.name,
              path: link.path,
              count: link.notification.counter,
            });
          }
        } else if (link.dropdown.length > 0) {
          link.dropdown.forEach((item) => {
            if (item.notification.counter > 0)
              this.notifications.push({
                name: item.name,
                path: item.path,
                count: item.notification.counter,
              });
          });
        }
      });
    },
    redirectUser() {
      //links for assessment
      if (unit === "assessment" && (role === "basic" || role === "unithead")) {
        this.$router.replace({ path: "/assessment" }).catch(() => {});
      }

      //links for technical adviser
      if (unit == "stampduty") {
        this.$router.replace({ path: "/stamp_duty" }).catch(() => {});
      }

      //links for technical adviser
      if (unit == "technical adviser" && role == "basic") {
        this.$router.replace({ path: "/audits" }).catch(() => {});
      }

      //chairman's office
      if (unit == "chairman's office") {
        this.$router.replace({ path: "/audits" }).catch(() => {});
      }

      if (unit === "v") {
        this.$router.replace({ path: "/property_details" }).catch(() => {});
      }

      setTimeout(() => {
        this.showView = true;
      }, 500);
    },
    loadNotifications() {
      if (views.includes("Tcc")) {
        setInterval(() => {
          this.$store.dispatch("getRequests").then(() => {
            this.allLinks[0].notification.counter = this.getRequestCount;
            this.allLinks[0].notification.active = true;
            this.checkForNotifications();
            // this.updateNotifications(0, this.allLinks[0]);
          });
        }, 3000);
      } else {
        this.allLinks[0].notification.active = false;
      }
      if (views.includes("Audits") && unit === "chairman") {
        setInterval(() => {
          this.$store
            .dispatch("getAllAuditRequests2", { status: ["pending"] })
            .then((result) => {
              this.allLinks[2].notification.counter = result.length;
              this.allLinks[2].notification.active = true;
              this.checkForNotifications();
              // this.updateNotifications(0, this.allLinks[0]);
            });
        }, 3000);
      } else {
        this.allLinks[2].notification.active = false;
      }
      if (
        views.includes("StampDuty") &&
        (role == "director" || role == "administrator" || role == "unithead")
      ) {
        if (unit === "chairman") {
          setInterval(() => {
            this.$store
              .dispatch("stamp_duty/getStampDutyRequestsFromDB2", {
                status: "approved",
              })
              .then((result) => {
                this.allLinks[9].notification.counter = result.length;
                this.allLinks[9].notification.active = true;
                this.checkForNotifications();
                // this.updateNotifications(1, this.allLinks[5]);
              });
          }, 3000);
        } else if (unit === "finance") {
          setInterval(() => {
            this.$store
              .dispatch("stamp_duty/getStampDutyRequestsFromDB2", {
                status: "verified",
              })
              .then((result) => {
                this.allLinks[9].notification.counter = result.length;
                this.allLinks[9].notification.active = true;
                this.checkForNotifications();
                // this.updateNotifications(1, this.allLinks[5]);
              });
          }, 3000);
        } else if (unit === "stampduty") {
          setInterval(() => {
            this.$store
              .dispatch("stamp_duty/getStampDutyRequestsFromDB2", {
                status: "pending",
              })
              .then((result) => {
                this.allLinks[9].notification.counter = result.length;
                this.allLinks[9].notification.active = true;
                this.checkForNotifications();
                // this.updateNotifications(1, this.allLinks[5]);
              });
          }, 3000);
        }
      } else {
        this.allLinks[9].notification.active = false;
      }
      if (
        views.includes("ManageReceipts") &&
        (role == "director" || role == "administrator")
      ) {
        if (unit === "chairman") {
          setInterval(() => {
            this.$store
              .dispatch("getPaymentReceipts", { status: "approved" })
              .then((result) => {
                this.allLinks[6].dropdown[0].notification.counter =
                  result.data.length;
                this.allLinks[6].dropdown[0].notification.active = true;
                this.checkForNotifications();
                // this.updateNotifications(1, this.allLinks[6]);
              });
          }, 5500);
          setInterval(() => {
            this.$store
              .dispatch("getPaymentsForApproval", { status: "approved" })
              .then((result) => {
                this.allLinks[6].dropdown[1].notification.counter =
                  result.data.length;
                this.allLinks[6].dropdown[1].notification.active = true;
                this.checkForNotifications();
                // this.updateNotifications(1, this.allLinks[6]);
              });
          }, 6500);
        } else if (unit === "collections" || unit === "administrator") {
          setInterval(() => {
            this.$store
              .dispatch("getPaymentReceipts", { status: "pending" })
              .then((result) => {
                this.allLinks[6].dropdown[0].notification.counter =
                  result.data.length;
                this.allLinks[6].dropdown[0].notification.active = true;
                this.checkForNotifications();
                // this.updateNotifications(1, this.allLinks[6]);
              });
          }, 5500);
          setInterval(() => {
            this.$store
              .dispatch("getPaymentsForApproval", { status: "pending" })
              .then((result) => {
                this.allLinks[6].dropdown[1].notification.counter =
                  result.data.length;
                this.allLinks[6].dropdown[1].notification.active = true;
                this.checkForNotifications();
                // this.updateNotifications(1, this.allLinks[6]);
              });
          }, 6500);
        }
      } else {
        this.allLinks[5].dropdown.forEach((item) => {
          item.notification.active = false;
        });
      }
    },
    updateNotifications(index, link) {
      this.notifications[index] = {
        name: link.name,
        path: link.path,
        count: link.notification.counter,
      };
    },
  },
  created() {
    // console.log(this.getAuditRequests);
    // this.loadNotifications();
  },
  async beforeMount() {
    await loadDetails();
    if (process.env.NODE_ENV === "production") {
      this.loadNotifications();
    }
    this.links = this.allLinks;
    this.user = user;
    this.designation = designation;

    if (
      unit !== "administrator" &&
      unit !== "chairman" &&
      unit !== "accounts" &&
      mda !== 1
    ) {
      this.links[6].dropdown.splice(1, 1);
    } else if (unit === "chairman" && role !== "director") {
      this.links[6].dropdown.splice(1, 1);
    }
    this.redirectUser();
  },
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
