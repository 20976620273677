import { STORAGE_ITEM_NAME, SECRET_KEY } from "@/helpers/keys";
import CryptoJS from "crypto-js";

export const cookies = {
  setCookie: (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();

    let encryptedText = CryptoJS.AES.encrypt(
      typeof cvalue == Object ? JSON.stringify(cvalue) : cvalue,
      SECRET_KEY
    ).toString();
    document.cookie = cname + "=" + encryptedText + ";" + expires + ";path=/";
  },
  getCookie: (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        // console.log(c.substring(name.length, c.length));
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  getJSONCookie: (cname) => {
    try {
      let cookie = cookies.getCookie(cname);

      if (cookie) {
        var bytes = CryptoJS.AES.decrypt(cookie, SECRET_KEY);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        // if (decryptedData) {
        //   if (decryptedData.loginDetails) {
        //     if (!decryptedData.loginDetails.mda) {
        //       cookies.setCookie(
        //         STORAGE_ITEM_NAME,
        //         JSON.stringify({
        //           status: "false",
        //           data: {},
        //         }),
        //         365
        //       );
        //       // window.location.href = "/login";
        //     }
        //   }
        // }

        return decryptedData;
      }

      return "";
    } catch (error) {
      if (error.message === "Malformed UTF-8 data") {
        alert("App updated, kindly login...");
        cookies.setCookie(
          STORAGE_ITEM_NAME,
          JSON.stringify({ status: "false", data: {} }),
          365
        );
        window.location.href = "/login";
      } else console.log(error);
    }
  },
  getUnitFromCookie() {
    if (cookies.getJSONCookie(STORAGE_ITEM_NAME) == "") return "";
    else if (cookies.getJSONCookie(STORAGE_ITEM_NAME).status == "false")
      return "";
    else return cookies.getJSONCookie(STORAGE_ITEM_NAME).loginDetails.unit;
  },
  getLoginDetailsFromCookie() {
    if (cookies.getJSONCookie(STORAGE_ITEM_NAME) == "") return "";
    else if (cookies.getJSONCookie(STORAGE_ITEM_NAME).status == "false")
      return "";
    else return cookies.getJSONCookie(STORAGE_ITEM_NAME).loginDetails;
  },
  getLoginStateFromCookie() {
    // console.log(cookies.getJSONCookie(STORAGE_ITEM_NAME));
    if (cookies.getJSONCookie(STORAGE_ITEM_NAME) == "") return "false";
    else return cookies.getJSONCookie(STORAGE_ITEM_NAME).status;
  },
};
